<!-- eslint-disable -->
<template>
  <div class="icon-marker-with-shadow" :class="{ 'animating': isAnimating }" :style="`--color: ${color}`">
    <span v-if="counter" class="counter">{{ counter }}</span>
  <svg xmlns="http://www.w3.org/2000/svg" width="68.966" height="120.259" viewBox="0 0 68.966 90.259">
    <g id="Group_3257" data-name="Group 3257" transform="translate(-587.101 -867.008)">
      <g id="Group_3256" data-name="Group 3256" transform="translate(587.101 867.008)">
        <g id="Group_3255" data-name="Group 3255">
          <g id="Group_3254" data-name="Group 3254">
            <g id="Group_3252" data-name="Group 3252" transform="translate(4.075)">
              <path id="Path_38115" data-name="Path 38115" d="M652.553,896.167a30.405,30.405,0,1,0-58.389,13.1c6.671,18.07,25.213,39.67,27.727,42.1a.417.417,0,0,0,.575.007c2.752-2.53,24.232-26.072,29.117-46.2.05-.191.094-.384.141-.577s.093-.386.134-.579a30.353,30.353,0,0,0,.63-4.287l.013-.168c.05-.711.084-1.426.084-2.149C652.584,896.971,652.571,896.56,652.553,896.167Zm-30.377,17.02a15.766,15.766,0,1,1,15.766-15.766A15.766,15.766,0,0,1,622.176,913.187Z" transform="translate(-591.769 -867.008)" :fill="color"/>
              <path id="Path_38116" data-name="Path 38116" d="M597.366,897.847a24.9,24.9,0,0,1,1.57-9.919,25.373,25.373,0,0,1,5.447-8.579,25.747,25.747,0,0,1,8.434-5.8,25.741,25.741,0,0,1,20.129,0,25.728,25.728,0,0,1,8.434,5.8,25.347,25.347,0,0,1,5.447,8.579,24.9,24.9,0,0,1,1.57,9.919.243.243,0,0,1-.254.232.247.247,0,0,1-.232-.232,25.761,25.761,0,0,0-7.787-17.242,24.841,24.841,0,0,0-34.485,0,25.768,25.768,0,0,0-7.787,17.242.243.243,0,0,1-.254.232A.248.248,0,0,1,597.366,897.847Z" transform="translate(-592.475 -867.578)" :fill="color" opacity="0.3" style="mix-blend-mode: screen;isolation: isolate"/>
              <path id="Path_38117" data-name="Path 38117" d="M623.644,878.58a20.309,20.309,0,1,0,20.31,20.31A20.309,20.309,0,0,0,623.644,878.58Zm0,36.075A15.766,15.766,0,1,1,639.41,898.89,15.766,15.766,0,0,1,623.644,914.655Z" transform="translate(-593.236 -868.476)" :fill="color"/>
              <path id="Path_38118" data-name="Path 38118" d="M622.857,933.685a34.205,34.205,0,0,1-24.885-10.693c8.055,15.633,22.508,33.19,24.706,35.314a.417.417,0,0,0,.574.008c2.311-2.125,17.836-20.042,25.641-36.6A34.224,34.224,0,0,1,622.857,933.685Z" transform="translate(-592.556 -873.949)" :fill="color" opacity="0.2" style="mix-blend-mode: multiply;isolation: isolate"/>
              <path id="Path_38119" data-name="Path 38119" d="M641.711,929.908a.242.242,0,0,0-.317.12l-.005.011a37.624,37.624,0,0,1-3.8,6.156q-2.147,2.94-4.463,5.757c-2.938,3.586-5.995,7.085-9.123,10.5-3.128-3.417-6.185-6.916-9.123-10.5-1.539-1.881-3.032-3.8-4.462-5.757a37.557,37.557,0,0,1-3.8-6.156l-.005-.011a.243.243,0,0,0-.444.2,38.036,38.036,0,0,0,3.707,6.351q2.089,3.015,4.346,5.9,4.5,5.769,9.323,11.263a.552.552,0,0,0,.047.065.586.586,0,0,0,.165.1l.009.005a.594.594,0,0,0,.159.038.517.517,0,0,0,.081.012.53.53,0,0,0,.082-.012.589.589,0,0,0,.158-.038l.01-.005a.61.61,0,0,0,.165-.1.682.682,0,0,0,.047-.065q4.822-5.509,9.322-11.263c1.5-1.926,2.954-3.89,4.346-5.9a38.1,38.1,0,0,0,3.707-6.351A.243.243,0,0,0,641.711,929.908Z" transform="translate(-593.593 -874.986)" :fill="color" opacity="0.3" style="mix-blend-mode: screen;isolation: isolate"/>
              <path id="Path_38120" data-name="Path 38120" d="M633.8,880.472a11.364,11.364,0,0,1,3.256,1.495c-1.655,2.081-2.375,3.016-2.375,3.016l-1.671-.756Z" transform="translate(-597.001 -868.716)" :fill="color" opacity="0.2" style="mix-blend-mode: multiply;isolation: isolate"/>
              <path id="Path_38121" data-name="Path 38121" d="M609.687,892.014c1.442-2.456,3.31-5.338,5.712-6.353-1.015-1.922-1.868-3.417-1.868-3.417a18.276,18.276,0,0,0-7.313,8.114C607.872,891.106,609.687,892.014,609.687,892.014Z" transform="translate(-593.602 -868.941)" :fill="color" opacity="0.2" style="mix-blend-mode: multiply;isolation: isolate"/>
              <path id="Path_38122" data-name="Path 38122" d="M633.8,922.92a11.337,11.337,0,0,0,3.256-1.495c-1.655-2.081-2.375-3.016-2.375-3.016l-1.671.756Z" transform="translate(-597.001 -873.53)" :fill="color" opacity="0.3" style="mix-blend-mode: screen;isolation: isolate"/>
              <path id="Path_38123" data-name="Path 38123" d="M609.687,910.247c1.442,2.455,3.31,5.338,5.712,6.352-1.015,1.922-1.868,3.417-1.868,3.417a18.275,18.275,0,0,1-7.313-8.114C607.872,911.154,609.687,910.247,609.687,910.247Z" transform="translate(-593.602 -872.494)" :fill="color" opacity="0.2" style="mix-blend-mode: screen;isolation: isolate"/>
              <path id="Path_38124" data-name="Path 38124" d="M623.533,868.781a36.556,36.556,0,0,1,21.06,6.7,30.4,30.4,0,0,0-42.13.007A36.56,36.56,0,0,1,623.533,868.781Z" transform="translate(-593.126 -867.008)" :fill="color" opacity="0.1" style="mix-blend-mode: screen;isolation: isolate"/>
              <path id="Path_38125" data-name="Path 38125" d="M643.705,905.945a19.918,19.918,0,0,1-2.277,6.584,20.483,20.483,0,0,1-4.421,5.424,20.14,20.14,0,0,1-6.014,3.613,20.443,20.443,0,0,1-13.838.258,20.163,20.163,0,0,1-6.145-3.383,20.545,20.545,0,0,1-4.622-5.254,19.881,19.881,0,0,1-2.523-6.493,20.479,20.479,0,0,0,13.436,14.7,20.421,20.421,0,0,0,13.532-.251,20.546,20.546,0,0,0,12.872-15.2Z" transform="translate(-593.304 -871.948)" :fill="color" opacity="0.4" style="mix-blend-mode: screen;isolation: isolate"/>
              <path id="Path_38126" data-name="Path 38126" d="M603.408,897.219a19.924,19.924,0,0,1,1.844-7.341,20.328,20.328,0,0,1,10.933-10.248,20.347,20.347,0,0,1,21.471,4.16,20.222,20.222,0,0,1,4.445,6.183,19.931,19.931,0,0,1,1.806,7.351l-.117-.94-.16-.933c-.062-.309-.132-.616-.2-.925s-.161-.609-.24-.913a20.027,20.027,0,0,0-1.374-3.5A20.4,20.4,0,0,0,631,880.232a20.285,20.285,0,0,0-14.595-.038,20.321,20.321,0,0,0-6.337,3.892A20.58,20.58,0,0,0,603.408,897.219Z" transform="translate(-593.245 -868.43)" :fill="color" opacity="0.3" style="mix-blend-mode: screen;isolation: isolate"/>
            </g>
            <g id="Group_3253" data-name="Group 3253" transform="translate(0 83.098)" opacity="0.8">
              <ellipse id="Ellipse_55" data-name="Ellipse 55" cx="34.483" cy="8.08" rx="34.483" ry="8.08" :fill="secondaryColor ? secondaryColor : color" opacity="0.5" style="mix-blend-mode: multiply;isolation: isolate"/>
              <ellipse id="Ellipse_56" data-name="Ellipse 56" cx="20.503" cy="4.804" rx="20.503" ry="4.804" transform="translate(13.981 3.276)" :fill="color" opacity="0.5" style="mix-blend-mode: multiply;isolation: isolate"/>
              <ellipse id="Ellipse_57" data-name="Ellipse 57" cx="9.279" cy="2.174" rx="9.279" ry="2.174" transform="translate(25.204 5.906)" :fill="color" opacity="0.5" style="mix-blend-mode: multiply;isolation: isolate"/>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>


  </div>
</template>
<!-- eslint-enable -->

<script>
export default {
  name: 'IconMarkerWithShadow',
  props: {
    color: {
      type: String,
      required: false,
      default: '#3EAE58',
    },
    isAnimating: {
      type: Boolean,
      required: false,
      default: false,
    },
    counter: {
      type: Number,
      required: false,
    },
    secondaryColor: {
      type: String,
      required: false,
    },
  },
  components: {},
  data: () => ({
  }),
  created() {},
  computed: {},
  methods: {},
};
</script>

<style lang='scss' scoped>
@keyframes counterPulse {
   0% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-15px);
  }
  80% {
    transform: translateY(0);
  }
}

@keyframes markerPulse {
  0% {
    transform: translate(4.075px, 0);
  }
  40% {
    transform: translate(4.075px, -15px);
  }
  80% {
    transform: translate(4.075px, 0);
  }
}

.icon-marker-with-shadow {
  position: relative;
}

.counter {
  width: 31px;
  height: 31px;
  border-radius: 100%;
  color: var(--color);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 30px;
  left: 19px;
  z-index: 1;
  font-size: 17px;
  font-weight: 700;
  background-color: $white;
}

#Group_3252 {
  transform: translate(4.075px);
}

.animating {
  .counter {
    animation: counterPulse 2s infinite;
  }

  #Group_3252 {
    animation: markerPulse 2s infinite;
  }
}
</style>
