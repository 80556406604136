<template>
  <div id='cart-collapsible'>
    <div class="cart-button" @click="handleCartBtnClick">
      <icon-cart :color="'#fff'" />
      <span class="counter">{{ itemsNumber }}</span>
    </div>
    <div class="cart-content scrollable" :class="{ 'opened': isCartOpened }" v-on-clickaway="handleClickAway">
      <cart-module
        :animateOnChange="false"
        :hasMaxHeight="true"
        :titleKey="'pages.home.sections.cartModule.title'"
        @buttonClicked="handlePayBtnClick"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { mixin as clickaway } from 'vue-clickaway';

import CartModule from '@/components/shared/modules/carts/Cart';
import IconCart from '@/components/shared/elements/icons/IconCart';

export default {
  name: 'CartCollapsible',
  mixins: [clickaway],
  props: {},
  components: {
    IconCart,
    CartModule,
  },
  data: () => ({
    isCartOpened: false,
  }),
  created() {
    this.$eventBus.onItemsAddedFromQTS(this, () => {
      this.isCartOpened = true;

      setTimeout(() => {
        this.setCartMaxHeight();
      }, 500);
    });
  },
  computed: {
    ...mapGetters({
      getCurrentCart: 'cart/getCurrentCart',
    }),
    itemsNumber() {
      if (this.getCurrentCart !== undefined && this.getCurrentCart.cartItems.length > 0) {
        const merged = [].concat.apply(...this.getCurrentCart.cartItems.map((obj) => obj.cikkList));
        return merged.map((item) => item.amount).reduce((prev, next) => prev + next);
      }
      return 0;
    },
  },
  methods: {
    handleCartBtnClick() {
      if (this.itemsNumber > 0) {
        this.isCartOpened = !this.isCartOpened;

        setTimeout(() => {
          this.setCartMaxHeight();
        }, 500);
      }
    },
    handleClickAway(e) {
      const classList = ['cart-button', 'counter'];
      const elementList = ['svg', 'path', 'circle'];

      if (!classList.includes(e.target.classList.value) && !elementList.includes(e.target.tagName.toLowerCase())) {
        this.isCartOpened = false;
      }
    },
    handlePayBtnClick() {
      this.$router.push('/payment/registration');
    },
    setCartMaxHeight() {
      const cartItems = document.querySelectorAll('.cart-items .cart-item');
      let height = 0;

      if (cartItems.length > 4) {
        for (let i = 0; i <= 4; i += 1) {
          height += cartItems[i].offsetHeight;
        }

        height += 20;
      } else {
        height = 1000;
      }

      document.querySelector('.cart-items.scrollable').style.setProperty('--max-height', `${height}px`);
    },
  },
};
</script>

<style lang='scss' scoped>
$btnSize: 86px;
$cartIconSize: 40px;
$counterSize: 31px;
$cartContentWidth: 395px;

.cart-button {
  width: $btnSize;
  height: $btnSize;
  border-radius: 100%;
  background-color: $primaryOrangeLight;
  color: $white;
  box-shadow: 0 15px 15px rgba($primaryOrangeLight, 0.35);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;

  @media screen and (max-width: $breakpointDownMd) {
    width: 70px;
    height: 70px;
  }

  .icon-cart {
    &::v-deep {
      svg {
        width: $cartIconSize;
        height: $cartIconSize;

        @media screen and (max-width: $breakpointDownMd) {
          width: 30px;
          height: 30px;
        }
      }
    }
  }
}

.counter {
  width: $counterSize;
  height: $counterSize;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $white;
  color: $primaryOrangeLight;
  font-size: 20px;
  font-weight: 700;
  position: absolute;
  top: -5px;
  right: 0;

  @media screen and (max-width: $breakpointDownMd) {
    width: calc(#{$counterSize} - 5px);
    height: calc(#{$counterSize} - 5px);
    font-size: 16px;
    padding: 1px 0 0 0.5px;
  }
}

.cart-content {
  width: $cartContentWidth;
  padding: 20px 32px;
  border-radius: $globalBorderRadius;
  box-shadow: $lightDropdownShadow;
  position: absolute;
  top: 50px;
  right: 30px;
  background-color: $white;
  z-index: 0;
  transform: scale(0);
  transform-origin: top right;
  opacity: 0;
  transition: $transitionBaseSlow;

  @media screen and (max-width: $breakpointDownMd) {
    top: 25px;
    right: 25px;
  }

  &.opened {
    transform: scale(1);
    opacity: 1;

    &.scrollable {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}

.cart-module {
  width: 100%;

  &::v-deep {
    .cart-content {
      padding: 0;
      box-shadow: none;
    }

    .cart-title {
      color: $primaryOrangeLight;
    }

    .cart-item {
      font-size: 12px;
    }

    .date {
      padding-left: 0;
      margin: 0 0 4px;
    }

    .cart-items-group {
      margin: 10px 0 0;
    }

    .summary-row {
      font-size: 15px;
      margin: 15px 0 0;
      padding: 0 15px;
    }
  }
}
</style>
