<template>
  <div class='facility-card' @click="handleCardClick">
    <div class="thumbnail">
      <img :src="thumbnailUrl">
    </div>
    <div class="name" :style="`--bgColor: ${color}`">
      <span class="text" v-html="name" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'FacilityCard',
  props: {
    imageUrl: {
      type: String,
      required: false,
      default: '/static/images/facilities/thumbnail-tmp.jpg',
    },
    name: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: false,
      default: 'DEFAULT',
    },
  },
  components: {},
  data: () => ({
  }),
  created() {},
  computed: {
    thumbnailUrl() {
      return this.getThumbnailForColor(this.color);
    },
  },
  methods: {
    handleCardClick() {
      this.$emit('clicked');
    },
    getThumbnailForColor(color) {
      switch (color) {
        case '#3E6FB5':
          return this.$FACILITIES.THUMBNAIL.BEACH;
        case '#3EAE58':
          return this.$FACILITIES.THUMBNAIL.THERMAL;
        case '#E07024':
          return this.$FACILITIES.THUMBNAIL.AQUA_PALACE;
        case '#D22226':
          return this.$FACILITIES.THUMBNAIL.AQUA_PARK;
        case '#E4D227':
          return this.$FACILITIES.THUMBNAIL.PREMIUM;
        case '#A370AE':
          return this.$FACILITIES.THUMBNAIL.POOL;
        default:
          return this.$FACILITIES.THUMBNAIL.DEFAULT;
      }
    },
  },
};
</script>

<style lang='scss' scoped>
$nameHeight: 43px;

.facility-card {
  border-radius: $globalBorderRadius;
  overflow: hidden;
  box-shadow: $lightDropdownShadow;
  background-color: $white;
  cursor: pointer;
  transition: $transitionBase;

  &:hover {
    box-shadow: none;

    .thumbnail img {
      transform: scale(1.1);
    }
  }
}

.thumbnail {
  height: 198px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    transition: $transitionBaseSlow;
  }

  @media screen and (max-width: $breakpointDownLg) {
    height: 170px;
  }

  @media screen and (max-width: $breakpointDownXs) {
    height: 190px;
  }
}

.name {
  text-align: center;
  height: $nameHeight;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--bgColor);
  color: $white;
  transition: $transitionBase;

  .text {
    font-size: 17px;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 1;

    &::v-deep {
      small {
        display: block;
        font-size: 9px;
      }
    }
  }
}
</style>
