<template>
  <div id='facilities-section'>
    <header>
      <h1 class="title">{{ $t('pages.home.sections.facilities.title') }}</h1>
      <h4 class="subtitle">{{ $t('pages.home.sections.facilities.subtitle')  }}</h4>
    </header>
    <section class="facilities-row">
      <facility-card
        v-for="(facility, i) in facilities" :key="i"
        :color="facility.facilityColor"
        :imageUrl="facility.imageUrl"
        :name="facility.cikkcsop_nev"
        @clicked="handleCardClick(facility.cikkcsop_id)"
      />
    </section>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import FacilityCard from '@/components/shared/modules/facilities/FacilityCard';

export default {
  name: 'FacilitiesSection',
  props: {},
  components: {
    FacilityCard,
  },
  data: () => ({
    facilities: undefined,
  }),
  created() {
    this.getCikkcsoports().then((resp) => {
      this.facilities = resp;
    });
  },
  computed: {},
  methods: {
    ...mapActions({
      getCikkcsoports: 'cikkcsoports/getCikkcsoports',
    }),
    handleCardClick(id) {
      this.$router.push(`/tickets/${id}`);
    },
  },
};
</script>

<style lang='scss' scoped>
#facilities-section {
  width: 100%;
}

header {
  text-align: center;
}

.title {
  color: $primaryBlue;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 47px;
  font-size: 40px;
  margin: 0;

  @media screen and (max-width: $breakpointDownMd) {
    font-size: 30px;
    line-height: 37px;
  }

  @media screen and (max-width: $breakpointDownSm) {
    font-size: 25px;
    line-height: 32px;
  }

  @media screen and (max-width: $breakpointDownXXs) {
    font-size: 20px;
    line-height: 27px;
  }
}

.subtitle {
  font-size: 15px;
  margin: 0;
  font-weight: 500;

  @media screen and (max-width: $breakpointDownMd) {
    font-size: 14px;
    line-height: 17px;
  }

  @media screen and (max-width: $breakpointDownSm) {
    font-size: 11px;
    line-height: 14px;
  }

  @media screen and (max-width: $breakpointDownXs) {
    font-size: 10px;
    line-height: 13px;
  }
}

.facilities-row {
  display: flex;
  justify-content: center;
  margin: 59px -5.5px;

  @media screen and (max-width: $breakpointDownMd) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 11px;
    row-gap: 11px;
    margin-top: 35px;
    margin-left: 0;
    margin-right: 0;
  }

  @media screen and (max-width: $breakpointDownXs) {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 11px;
    row-gap: 20px;
    margin-bottom: 0;
  }

  .facility-card {
    width: calc(100% / 6);
    margin: 0 5.5px;

    @media screen and (max-width: $breakpointDownMd) {
      width: 100%;
      margin: 0;
    }
  }
}
</style>
