<template>
  <div id='section-map'>
    <div class="container hungarospa-container">
      <header>
        <h1 class="title">{{ $t('pages.home.sections.map.title') }}</h1>
        <h4 class="subtitle">{{ $t('pages.home.sections.map.subtitle') }}</h4>
      </header>
    </div>
    <div class="container hungarospa-container map-container">
      <section class="content">
        <cart-collapsible />
        <quick-ticket-selector
          :isVisible="isQuickSelectorVisible"
          :enumKey="selectedFacilityEnumKey"
          @clickedAway="closeQuickTicketSelector"
        />
        <hungarospa-map ref="mapRef" @facilitySelected="handleFacilitySelection"/>
      </section>
    </div>
    <div class="container self-service-instruction">
      <span class="instruction">{{ $t('pages.home.sections.selfServiceInstruction') }}</span>
    </div>
  </div>
</template>

<script>
import CartCollapsible from '@/components/shared/modules/carts/CartCollapsible';
import HungarospaMap from '@/components/shared/elements/partials/HungarospaMap';

export default {
  name: 'MapSection',
  props: {},
  components: {
    HungarospaMap,
    CartCollapsible,
    'quick-ticket-selector': () => import('@/components/shared/modules/tickets/QuickTicketSelector'),
  },
  data: () => ({
    isQuickSelectorVisible: false,
    selectedFacilityEnumKey: 'BEACH',
  }),
  created() {
    this.$eventBus.onItemsAddedFromQTS(this, () => {
      if (this.$refs.mapRef) {
        this.$refs.mapRef.selectedFacilityIndex = undefined;
      }
      this.isQuickSelectorVisible = false;
    });

    document.addEventListener('keyup', this.handleKeyPress);
  },
  computed: {},
  methods: {
    openQuickTicketSelector() {
      this.isQuickSelectorVisible = true;
    },
    handleFacilitySelection(enumKey) {
      this.openQuickTicketSelector(enumKey);
      this.selectedFacilityEnumKey = enumKey;
    },
    handleKeyPress(e) {
      if (e.key === 'Escape') {
        this.$refs.mapRef.selectedFacilityIndex = undefined;
        this.isQuickSelectorVisible = false;
      }
    },
    closeQuickTicketSelector() {
      this.isQuickSelectorVisible = false;
    },
  },
  beforeDestroy() {
    document.removeEventListener('keyup', this.handleKeyPress);
  },
};
</script>

<style lang='scss' scoped>
.map-container, .self-service-instruction {
  @media screen and (max-width: $breakpointDownSm) {
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
  }

  .instruction {
    margin: auto 0 75px 60px;
    max-width: 360px;
    display: block;

    @media screen and (max-width: $breakpointDownMd) {
      margin-left: 0;
    }

    @media screen and (max-width: $breakpointDownSm) {
      margin: 30px auto 0;
    }
  }
}

header {
  text-align: center;
  margin: 0 0 30px;
}

.title {
  font-size: 40px;
  font-weight: 700;
  color: $primaryBlue;
  line-height: 47px;
  margin: 0;
  text-transform: uppercase;

  @media screen and (max-width: $breakpointDownMd) {
    font-size: 30px;
    line-height: 37px;
  }

  @media screen and (max-width: $breakpointDownSm) {
    font-size: 25px;
    line-height: 32px;
  }

  @media screen and (max-width: $breakpointDownXXs) {
    font-size: 20px;
    line-height: 27px;
  }
}

.subtitle {
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  margin: 0;

  @media screen and (max-width: $breakpointDownMd) {
    font-size: 14px;
    line-height: 17px;
  }

  @media screen and (max-width: $breakpointDownSm) {
    font-size: 11px;
    line-height: 14px;
  }

  @media screen and (max-width: $breakpointDownXs) {
    font-size: 10px;
    line-height: 13px;
  }
}

#hungarospa-map {
  margin: 0 auto;
}

.map-container {
  .content {
    position: relative;
  }

  #cart-collapsible {
    position: absolute;
    top: 15px;
    right: 95px;
    z-index: z('page-body');

    @media screen and (max-width: $breakpointDownXl) {
      right: 45px;
    }

    @media screen and (max-width: $breakpointDownMd) {
      right: 35px;
    }

    @media screen and (max-width: $breakpointDownSm) {
      display: none;
    }
  }
}
</style>
