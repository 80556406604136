<template>
  <div class='facility-selector'>
    <div class="logo"><img src="/static/images/logos/hungarospa-logo_colorfull.svg"></div>
    <div class="selectors">
      <facilitiy-button
        v-for="(facility, i) in facilities" :key="i"
        :selected="facility.key === selected"
        :text="facility.text"
        :color="facility.color"
        :secondaryColor="facility.secondaryColor"
        :counter="i + 1"
        @clicked="handleButtonClick(facility.key, facility.enumKey)"
        @mouseover.native="handleHover('enter', facility.key)"
        @mouseleave.native="handleHover('leave', facility.key)"
      />
    </div>
  </div>
</template>

<script>
import FacilitiyButton from '@/components/shared/modules/facilities/FacilityButton';

export default {
  name: 'FacilitySelector',
  props: {
    selected: {
      type: String,
      required: false,
    },
  },
  components: {
    FacilitiyButton,
  },
  data: () => ({
  }),
  created() {},
  computed: {
    facilities() {
      return [
        {
          enumKey: 'THERMAL',
          key: this.$FACILITIES.TYPE.THERMAL,
          text: this.$t(this.$FACILITIES.NAME_KEY.THERMAL),
          color: this.$FACILITIES.COLOR.THERMAL,
        },
        {
          enumKey: 'BEACH',
          key: this.$FACILITIES.TYPE.BEACH,
          text: this.$t(this.$FACILITIES.NAME_KEY.BEACH),
          color: this.$FACILITIES.COLOR.BEACH,
          secondaryColor: '#3970B6',
        },
        {
          enumKey: 'AQUA_PALACE',
          key: this.$FACILITIES.TYPE.AQUA_PALACE,
          text: this.$t(this.$FACILITIES.NAME_KEY.AQUA_PALACE),
          color: this.$FACILITIES.COLOR.AQUA_PALACE,
        },
        {
          enumKey: 'AQUA_PARK',
          key: this.$FACILITIES.TYPE.AQUA_PARK,
          text: this.$t(this.$FACILITIES.NAME_KEY.AQUA_PARK),
          color: this.$FACILITIES.COLOR.AQUA_PARK,
        },
        {
          enumKey: 'PREMIUM',
          key: this.$FACILITIES.TYPE.PREMIUM,
          text: this.$t(this.$FACILITIES.NAME_KEY.PREMIUM),
          color: this.$FACILITIES.COLOR.PREMIUM,
        },
        {
          enumKey: 'POOL',
          key: this.$FACILITIES.TYPE.POOL,
          text: this.$t(this.$FACILITIES.NAME_KEY.POOL),
          color: this.$FACILITIES.COLOR.POOL,
        },
      ];
    },
  },
  methods: {
    handleButtonClick(key, enumKey) {
      this.$emit('selected', { key, enumKey });
    },
    handleHover(event, key) {
      switch (event) {
        case 'enter':
          this.$emit('hovered', key);
          break;
        case 'leave':
          this.$emit('hoverEnded', key);
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style lang='scss' scoped>
$logoWidth: 294px;
$logoHeight: 97px;

.selectors {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  column-gap: 16px;
  row-gap: 20px;
}

.logo {
  margin: 0 auto 34px;
  width: $logoWidth;
  height: $logoHeight;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
</style>
