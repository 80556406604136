<template>
  <div id='hungarospa-map' :class="{ 'bg-no-webp' : !isSupportWebp  }">
    <icon-marker-with-shadow
      v-for="(marker, i) in facilityMarkers" :key="i"
      :isAnimating="i === activeFacilityIndex"
      :class="marker.className"
      :color="marker.color"
      :counter="i + 1"
      :secondaryColor="marker.secondaryColor" />
    <img src="/static/images/logos/hungarospa-logo_colorfull.svg" class="mobile-logo">
    <facility-selector :selected="selectedFacilityKey"
      @selected="handleSelection"
      @hovered="handleSelectorHover('enter', ...arguments)"
      @hoverEnded="handleSelectorHover('leave', ...arguments)" />
  </div>
</template>

<script>
import IconMarkerWithShadow from '@/components/shared/elements/icons/IconMarkerWithShadow';
import FacilitySelector from '@/components/shared/modules/facilities/FacilitySelector';

export default {
  name: 'HungarospaMap',
  props: {},
  components: {
    IconMarkerWithShadow,
    FacilitySelector,
  },
  data: () => ({
    activeFacilityIndex: undefined,
    selectedFacilityIndex: undefined,
    isSupportWebp: true,
  }),
  created() {
    this.$eventBus.onQuickTicketSelectorClosed(this, () => {
      this.selectedFacilityIndex = undefined;
    });

    this.isSupportWebp = document.createElement('canvas').toDataURL('image/webp').indexOf('data:image/webp') === 0;
  },
  computed: {
    facilityMarkers() {
      return [
        {
          key: this.$FACILITIES.TYPE.THERMAL,
          className: 'thermal',
          color: this.$FACILITIES.COLOR.THERMAL,
        },
        {
          key: this.$FACILITIES.TYPE.BEACH,
          className: 'beach',
          color: this.$FACILITIES.COLOR.BEACH,
          secondaryColor: '#3970B6',
        },
        {
          key: this.$FACILITIES.TYPE.AQUA_PALACE,
          className: 'aqua-palace',
          color: this.$FACILITIES.COLOR.AQUA_PALACE,
        },
        {
          key: this.$FACILITIES.TYPE.AQUA_PARK,
          className: 'aqua-park',
          color: this.$FACILITIES.COLOR.AQUA_PARK,
        },
        {
          key: this.$FACILITIES.TYPE.PREMIUM,
          className: 'premium',
          color: this.$FACILITIES.COLOR.PREMIUM,
        },
        {
          key: this.$FACILITIES.TYPE.POOL,
          className: 'pool',
          color: this.$FACILITIES.COLOR.POOL,
        },
      ];
    },
    selectedFacilityKey() {
      return this.facilityMarkers[this.selectedFacilityIndex]
        ? this.facilityMarkers[this.selectedFacilityIndex].key
        : undefined;
    },
  },
  methods: {
    handleSelection(payload) {
      this.selectedFacilityIndex = this.facilityMarkers.findIndex((x) => x.key === payload.key);
      this.$emit('facilitySelected', payload.enumKey);
    },
    handleSelectorHover(event, key) {
      switch (event) {
        case 'enter':
          this.activeFacilityIndex = this.facilityMarkers.findIndex((x) => x.key === key);
          break;
        case 'leave':
          this.activeFacilityIndex = undefined;
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style lang='scss' scoped>
#hungarospa-map {
  width: 1108px;
  height: 815px;
  display: flex;
  position: relative;
  background: transparent url('/static/images/illustrations/hungarospa-map.webp') no-repeat;
  background-size: contain;

  &.bg-no-webp {
    background: transparent url('/static/images/illustrations/hungarospa-map.jpg') no-repeat;
    background-size: contain;
  }

  @media screen and (max-width: $breakpointDownLg) {
    width: 100%;
  }

  @media screen and (max-width: $breakpointDownMd) {
    height: 680px;
  }

  @media screen and (max-width: $breakpointDownSm) {
    width: 535px;
    height: calc(420px + 150px); // map height + facility selector
  }

  @media screen and (max-width: $breakpointDownXs) {
    width: 390px;
    height: calc(320px + 150px);
  }

  @media screen and (max-width: $breakpointDownXXs) {
    width: 290px;
    height: calc(250px + 150px);
  }
}

@keyframes counterPulseMobile {
   0% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  80% {
    transform: translateY(0);
  }
}

.icon-marker-with-shadow {
  position: absolute;

  &::v-deep {
    svg {
      @media screen and (max-width: $breakpointDownMd) {
        width: 50px;
      }
    }

    .counter {
      @media screen and (max-width: $breakpointDownMd) {
        width: 23px;
        height: 23px;
        left: 13px;
        top: 38px;
      }
    }

    &.animating .counter {
      @media screen and (max-width: $breakpointDownMd) {
        animation: counterPulseMobile 2s infinite;;
      }
    }
  }
}

.thermal {
  top: 124px;
  left: 45px;

  @media screen and (max-width: $breakpointDownLg) {
    top: 90px;
    left: 30px;
  }

  @media screen and (max-width: $breakpointDownMd) {
    top: 54px;
  }

  @media screen and (max-width: $breakpointDownSm) {
    top: 20px;
    left: 20px;
  }

  @media screen and (max-width: $breakpointDownXs) {
    top: -10px;
    left: 10px;
  }

  @media screen and (max-width: $breakpointDownXXs) {
    top: -30px;
  }
}

.beach {
  top: 70px;
  left: 145px;

  @media screen and (max-width: $breakpointDownLg) {
    top: 40px;
    left: 120px;
  }

  @media screen and (max-width: $breakpointDownSm) {
    left: 60px;
    top: -15px;
  }

  @media screen and (max-width: $breakpointDownXs) {
    top: -40px;
    left: 52px;
  }
}

.aqua-palace {
  top: 320px;
  left: 367px;

  @media screen and (max-width: $breakpointDownLg) {
    left: 300px;
    top: 250px;
  }

  @media screen and (max-width: $breakpointDownMd) {
    top: 170px;
    left: 215px;
  }

  @media screen and (max-width: $breakpointDownSm) {
    left: 166px;
    top: 110px;
  }

  @media screen and (max-width: $breakpointDownXs) {
    left: 115px;
    top: 55px;
  }

  @media screen and (max-width: $breakpointDownXXs) {
    left: 80px;
    top: 17px;
  }
}

.aqua-park {
  bottom: 155px;
  right: 320px;

  @media screen and (max-width: $breakpointDownLg) {
    bottom: 255px;
    right: 270px;
  }

  @media screen and (max-width: $breakpointDownMd) {
    right: 200px;
  }

  @media screen and (max-width: $breakpointDownSm) {
    bottom: auto;
    top: 220px;
    right: 150px;
  }

  @media screen and (max-width: $breakpointDownXs) {
    right: 100px;
    top: 130px;
  }

  @media screen and (max-width: $breakpointDownXXs) {
    top: 75px;
    right: 70px;
  }
}

.premium {
  top: 10px;
  right: 360px;

  @media screen and (max-width: $breakpointDownLg) {
    top: -20px;
    right: 300px;
  }

  @media screen and (max-width: $breakpointDownMd) {
    right: 220px;
  }

  @media screen and (max-width: $breakpointDownSm) {
    right: 160px;
    top: -40px;
  }

  @media screen and (max-width: $breakpointDownXs) {
    right: 110px;
    top: -54px;
  }

  @media screen and (max-width: $breakpointDownXXs) {
    right: 80px;
  }
}

.pool {
  bottom: 230px;
  right: 75px;

  @media screen and (max-width: $breakpointDownLg) {
    bottom: 330px;
    right: 50px;
  }

  @media screen and (max-width: $breakpointDownMd) {
    bottom: 300px;
    right: 40px;
  }

  @media screen and (max-width: $breakpointDownSm) {
    bottom: auto;
    top: 180px;
    right: 25px;
  }

  @media screen and (max-width: $breakpointDownXs) {
    top: 110px;
    right: 13px;
  }

  @media screen and (max-width: $breakpointDownXXs) {
    top: 55px;
  }
}

.facility-selector {
  width: 360px;
  margin: auto 0 75px 60px;

  @media screen and (max-width: $breakpointDownMd) {
    margin-left: 0;
  }

  @media screen and (max-width: $breakpointDownSm) {
    margin: auto auto 0;
  }

  &::v-deep {
    .logo {
      @media screen and (max-width: $breakpointDownMd) {
        width: 230px;
        height: auto;
      }

      @media screen and (max-width: $breakpointDownSm) {
        display: none;
      }
    }

    button {
      &.selected,
      &:hover {
        .pin {
          @media screen and (max-width: $breakpointDownXXs) {
            left: 5px;
          }
        }
      }
    }

    .pin {
      @media screen and (max-width: $breakpointDownXXs) {
        left: 5px;
        padding: 0;
      }
    }
  }
}

.mobile-logo {
  display: none;
  position: absolute;
  top: 250px;
  left: 25px;
  width: 200px;
  height: auto;

  @media screen and (max-width: $breakpointDownSm) {
    display: block;
  }

  @media screen and (max-width: $breakpointDownXs) {
    width: 140px;
    top: 190px;
  }

  @media screen and (max-width: $breakpointDownXXs) {
    width: 120px;
    top: 140px;
    left: 0;
  }
}
</style>
