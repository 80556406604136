import { render, staticRenderFns } from "./HungarospaMap.vue?vue&type=template&id=924b885a&scoped=true&"
import script from "./HungarospaMap.vue?vue&type=script&lang=js&"
export * from "./HungarospaMap.vue?vue&type=script&lang=js&"
import style0 from "./HungarospaMap.vue?vue&type=style&index=0&id=924b885a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "924b885a",
  null
  
)

export default component.exports