<template>
  <div id='home-screen'>
    <map-section />
    <div class="container hungarospa-container">
      <transition mode="in-out">
        <facilities-section v-if="isFacilitySectionVisible" />
      </transition>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import MapSection from '@/components/shared/modules/sections/MapSection';
import FacilitiesSection from '@/components/shared/modules/sections/FacilitiesSection';

export default {
  name: 'HomeScreen',
  props: {},
  components: {
    MapSection,
    FacilitiesSection,
  },
  data: () => ({
    isFacilitySectionVisible: undefined,
  }),
  created() {
    this.getFacilitySectionVisibility().then((resp) => {
      this.isFacilitySectionVisible = resp.data;
    });
  },
  computed: {},
  methods: {
    ...mapActions({
      getFacilitySectionVisibility: 'settings/getFacilitySectionVisibility',
    }),
  },
};
</script>

<style lang='scss' scoped>
#section-map {
  padding-top: 40px;
  margin-bottom: 50px;
}

#facilities-section {
  margin-bottom: 59px;
  display: inline-block;
}
</style>
