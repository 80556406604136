<template>
  <button class="facility-button"
    :class="[{ 'selected': selected }, { 'has-secondary-color': secondaryColor }]" :style="`--color: ${color};
      ${ secondaryColor ? `--secondary-color: ${secondaryColor}` : ''}`" @click="handleClick">
    <div class="pin">
      <span v-if="counter" class="counter">{{ counter }}</span>
      <icon-marker :color="color" />
    </div>
    <span class="text" v-html="text" />
  </button>
</template>

<script>
import IconMarker from '@/components/shared/elements/icons/IconMarker';

export default {
  name: 'FacilityButton',
  props: {
    text: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
    counter: {
      type: [Number, String],
      required: false,
    },
    selected: {
      type: Boolean,
      required: false,
      default: false,
    },
    secondaryColor: {
      type: String,
      required: false,
    },
  },
  components: {
    IconMarker,
  },
  data: () => ({
  }),
  created() {},
  computed: {},
  methods: {
    handleClick() {
      this.$emit('clicked');
    },
  },
};
</script>

<style lang='scss' scoped>
$btnHeight: 38px;

button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $white;
  border: 0;
  border-radius: $globalBorderRadius;
  height: $btnHeight;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 12px;
  color: $globalFontColor;
  box-shadow: $facilitySelectorButtonShadow;
  position: relative;
  padding: 0 0 0 40px;

  &:focus {
    outline: none;
  }

  &.has-secondary-color {
    &.selected,
    &:hover {
      background-color: var(--secondary-color);
    }
  }

  &.selected,
  &:hover {
    background-color: var(--color);
    color: $white;

    .pin {
      left: 14px;
      bottom: 5px;
    }

    .counter {
      bottom: 26px;
      left: 11px;
      font-size: 14px;
      line-height: 20px;
    }

    .icon-marker {
      &::v-deep svg {
        width: 40px;
        height: 56px;

        .stroke {
          fill: $white;
        }
      }
    }
  }

  &::v-deep {
    small {
      display: block;
      font-size: 8px;
      line-height: 1;
      font-weight: 700;
    }
  }
}

.counter {
  position: absolute;
  bottom: 20px;
  left: 8px;
  color: var(--color);
  font-size: 12px;
  font-weight: 700;
  z-index: 2;
  transition: $transitionBase;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 17px;
  height: 17px;
  border-radius: 100%;
  background-color: $white;
}

.pin {
  position: absolute;
  bottom: -1px;
  left: 18px;
  transition: $transitionBase;
}

.icon-marker {
  position: relative;
  z-index: 1;

  &::v-deep {
    svg {
      width: 33px;
      height: 46px;
      transition: $transitionBase;

      .stroke {
        transition: $transitionBase;
      }
    }
  }
}
</style>
